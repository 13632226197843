.addContainer {
    position: relative;
}

.addContainer form label {
    font-size: 1.2rem;
}

.addContainer form input {
    display: block;
    font-size: 1.2rem;
    padding: 0.25em;
    margin-bottom: 1em;
    width: 45%;
    font-family: inherit;
}

.richArea {
    margin-bottom: 2em;
}

.addContainer div > button,
.addContainer div > a {
    font-size: 1.2rem;
    padding: 0.5em 1em;
    margin-left: 1em;
    border-radius: 3px;
    border: 1px solid #033F63;
    margin-bottom: 1em;
    transition: all 0.5s ease-out;
    color: #033F63
}

.addContainer div>button:first-child {
    background-color: #fefefe;
}

.addContainer div>button:nth-child(2) {
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    color: #033F63;
}

.addContainer div>a {
    margin-left: 0;
    text-decoration: none;
}

.addContainer div>button:hover,
.addContainer div > a:hover {
    color: #fefefe;
    background-color: #033F63;
}

.addContainer div>button:nth-child(2):hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
    color: #033F63;
}

.addContainer div > button > svg,
.addContainer div > a > svg {
    padding-right: 0.5em;
}

.textButtons { 
    display:flex;
    justify-content: space-between;
}

.loadingContainer {
    background-color: #fefefe;
    border: 2px solid #033F63;
    border-radius: 3px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    height: 65%;
    width: fit-content;
}

.displayPhoto {
    margin-left: 1em;
    margin-top: -1em;
    margin-bottom: 2em;
}

.displayPhoto img{
    width: 75px;
    border-radius: 3px;
}

.displayPhoto p {
    margin: 0;
    margin-bottom: 0.5em;
    font-style: italic;
    font-size: 1rem;
}

@media screen and (max-width: 48em) {
    .textButtons {
        display: flex;
    }

    .addContainer div>a,
    .addContainer div>button {
        font-size: 1rem;
    }

    .textButtons a {
        display: inline-block;
        font-size: 1rem;
    }

    .textButtons > div {
        display: inline-block;
    }

    .textButtons > div > button:first-child {
        margin-left: 0.5em;
    }

    .textButtons> div >button:nth-child(2) {
        margin-left: 0.5em;
    }

    .addContainer form input {
        width: 100%;
    }
}

@media screen and (max-width: 35.5em) {
    .textButtons > div {
        flex-grow: 1;
    }

    .textButtons > div > button {
        width: 100%;
    }
}