.dropdownButton {
    width: 100%;
    border: 0;
    background: none;
    /* border-bottom: 1px solid #033F63; */
    padding: 0;
}

.dropdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    padding: 1em 0;
    color: #033F63;
    transition: all 0.5s ease-out;
    overflow: hidden;
}

.faqItem {
    max-height: 0;
    transition: all 0.5s ease-out;
    overflow: hidden;
}

.faqItem ul {
    list-style-type: disc;
}

.faqItem li {
    margin: .5em 1em;
}

.open-content {
    max-height: 1500px;
}

.faqItem p {
    font-size: 1.1rem;
}