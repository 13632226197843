.blogPosts {
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.blogPosts > a {
    margin-left: 1.4em;
    text-decoration: none;
}

.blogPosts > a:first-child {
    margin-bottom: 1.5em;
}

.blogPosts > a {
    margin-bottom: 1em;
}

@media screen and (max-width: 48em) {
    
    .blogPosts>a {
    margin-left: 0;
    }

}

@media screen and (max-width: 23.75em) {
    .blogPosts>a:first-child {
    margin-bottom: 1em;
    }
}