.windowLocation {
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
}

.address {
    font-size: 1rem;
    line-height: 1.4;
    margin: 0.1em 0;
}