.navigation-bar {
    margin: 0.5em auto 0;
    position: relative;
}
.navigation-bar:after {
	content: "";
	display: block;
	position: relative;
	border-bottom: #ade29966 2px solid;
	z-index: -2;
}

.navigation-container {
    position: relative;
	flex-wrap: wrap;
}

.phone {
	flex: 0 0 100%;
	margin-bottom: -1.2em;
	font-size: 2rem;
	text-align: center;
	padding-right: 25%;
}
.phone a {
	text-decoration: none;
}

.navigation-container nav {
	align-self: flex-end;
}

.navigation-container ul {
    padding-bottom: 0.5em;
    margin-left: 1em;
    position: relative;
    z-index: 5;
	margin-bottom: 0;
}

.navigation-container ul li:first-of-type {
    display: none;
}

.navigation-container ul li.admin:first-of-type {
    display: block;
}

.navigation-container nav a {
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    text-decoration: none;
    padding: 0 1em;
    font-size: 1.25rem;
}

.nav-underline {
    border-bottom: 3px solid transparent;
    border-bottom-right-radius: 225px 10px;
    border-top-right-radius: 360px 250px;
    border-bottom-left-radius: 360px 30px;
    transition: all 0.5s ease-out;
}

.nav-underline:hover {
    border-bottom: 3px solid #033F63;
}

.anew-logo {
    width: 120px;
    height: auto;
}

.toggleButton {
    display: none;
}


@media screen and (min-width: 62em) and (max-width: 75em) {

    .navigation-container nav a {
        font-size: 1.1rem;
    }

}

@media screen and (min-width: 48em) and (max-width: 62em) {
    .anew-logo {
        width: 120px;
        height: auto;
    }

    .navigation-container {
        height: 6em;
        position: relative;
		flex-wrap: wrap;
    }

	.navigation-container .imgLink {
		margin-top: -6em;
	}

	.phone {
		font-size: 1.9rem;
		margin-bottom: 0.3em;
	}

    .toggleButton {
        width: 50px;
        height: 45px;
        background-color: #FEFEFE;
        border: none;
        margin: 0;
        padding: 0;
        position: relative;
        transition: all 1s ease-out;
    }

    .toggleButton .first,
    .toggleButton .second,
    .toggleButton .trio {
        height: 6px;
        width: 50px;
        border-radius: 5px;
        background-color: #444444;
        transition: all 0.5s ease-out;
        position: absolute;
    }
}


@media screen and (max-width: 48em) {

	.navigation-container .imgLink {
		margin-top: -2em;
	}

    .navigation-container {
        flex-wrap: wrap;
        height: initial;
    }

    .anew-logo {
        width: 75px;
        height: auto;
    }

	.phone {
		margin-bottom: 1em;
		font-size: 1rem;
	}

    .toggleButton {
        width: 35px;
        height: 25px;
        background-color: #FEFEFE;
        border: none;
        margin: 0;
        padding: 0;
        position: relative;
        transition: all 1s ease-out;
    }

    .toggleButton .first,
    .toggleButton .second,
    .toggleButton .trio {
        height: 3px;
        width: 35px;
        border-radius: 5px;
        background-color: #444444;
        transition: all 0.5s ease-out;
        position: absolute;
    }

}

@media screen and (max-width: 62em) {
	.navigation-container .imgLink {
		width: 100%;
		text-align: center;
	}
    
    .anew-logo {
		align-items: center;
	}
	.phone {
		text-align: left;
	}

    .toggleButton {
		display: inline;
	}
    
    .navigation-bar nav {
        position: absolute;
        top: 100%;
        left: 0;
        /* display: none; */
        opacity: 0;
        z-index: -1;
        transition: all 0.5s ease-out;
    }

    .navigation-bar nav.xmark {
        display: block;
        position: absolute;
        background-color: #FEFEFE;
        height: 300px;
        top: 100%;
        left: 0;
        z-index: 205;
        font-size: 1rem;
        box-shadow: 2px 2px 8px #444444;
        margin-left: 1rem; 
        opacity: 1;
        transition: all 0.5s ease-out;
    } 

    .navigation-bar ul {
        flex-direction: column;
        align-items: start;
        margin: 1em;
        padding: 0;
    }

    .navigation-bar ul a {
        padding: 0;
        text-align: left;
        font-size: 1rem;
    }

    .navigation-bar li {
        padding: 0.5em 0;
    }

    .navigation-container ul li:first-of-type {
            display: block;
        }

    .first {
        top: 0;
        left: 0;
    }

    .second {
        top: 43.5%;
        left: 0;
        opacity: 1;
    }

    .trio {
        bottom: 0;
        left: 0;
    }

    .second.xmark {
        opacity: 0;
        transform: scale(0.1);
    }

    .trio.xmark {
        transform: rotate(-45deg);
        top: 50%;
    }

    .first.xmark {
        transform: rotate(45deg);
        top: 50%;
    };


    
}