.instantAccessWrapper {
	border-bottom: 2px solid #ade29966;
	padding-bottom: 2em;
	margin-bottom: 3em;
}

.instantAccess {
	background: linear-gradient(180deg,#f9d976,#ffbf3e);
    border: 1px solid #033f63;
    border-radius: 3px;
    color: #033f63;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.35em 3em;
    text-align: center;
    text-decoration: none;
}

.contactPage p {
    font-weight: 400;
}

.contactPage p {
    width: 90%;
}

.contactPage label,
.formContainer>.contactForm label {
    font-size: 1.25rem;
    font-weight: 400;
}

.formContainer {
    margin: 2em auto;
    width: 70%;
}

.formContainer > .contactForm input,
.formContainer > .contactForm textarea {
    display: block;
    line-height: 2;
    font-size: inherit;
    font-family: inherit;
    border: 1px solid #033F63;
    border-radius: 3px;
    padding-left: 0.5em;
    font-size: 1.25rem;
}

.formContainer > .contactForm div {
    margin-bottom: 2em;
    padding: 0;
}

.contactForm input {
    width: 20em;
}

.contactForm input[type="checkbox"] {
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    font-size: 2rem ;
    margin-bottom: 1.5em;
}

.contactForm textarea {
    width: 100%;
}

.contactForm input:focus-within, 
.contactForm textarea:focus-within {
    outline: 1px solid #033F63;
    background-color: #F5FAFD;
}

.contactForm button {
    font-size: 1.25rem;
    font-family: inherit;
    padding: 0.5em 3em;
    border: 1px solid #033F63;
    border-radius: 5px;
    background-image: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    margin-top: 1em;
    transition: 2s ease-in all;
}

.contactForm button:hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
}

.contactForm button:focus-within {
    outline: 1px solid #033F63;
}


.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 2px solid #033F63;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #F5FAFD
}

.container input:checked~.checkmark {
    background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #033F63;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container input:focus~.checkmark {
    box-shadow: 0 0 0 0.5px #033F63;
}

.homeLink {
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    font-size: 1.25rem;
    padding: 0.5em 4em;
    border-radius: 3px;
    border: 2px solid #444444;
    text-decoration: none;
    font-weight: 400;
    color: #444444;
    margin-bottom: 2em;
    display: inline-block;
    text-align: center;
}



.sentMessageContainer { 
    text-align: center;
    margin: 0 auto;
}

.sentMessageContainer h2 {
    font-size: 3rem;
    margin-top: 1em;
}

.sentMessageContainer img {
    width: 325px;
    height: auto;
}

.sentMessageContainer p {
    width: 100%;
}

@media screen and (max-width: 48em) {

    .contactForm input {
        width: 100%;
    }

    .formContainer {
        margin: 0;
        width: 100%;
    }

    .contactPage label {
        font-size: 1rem;
    }

    .contactForm > div label:last-of-type {
        font-size: 0.8rem;
    }

    .contactForm button {
        width: 100%;
        margin-bottom: 4em;
    }

    .homeLink {
        width: 100%;
    }

    .sentMessageContainer img {
        width: 80%;
    }

    .sentMessageContainer h2 {
        font-size: 2.2rem;
    }

}