.loadingContainer {
    text-align: center;
    font-size: 2rem;
    margin-top: 2em;
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
}

.articleContainer h1+p {
    margin-bottom: 0;
    font-weight: 400;
}

.articleContainer h1 + p + p {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    font-weight: 400;
}

.articleContainer div {
    width: 90%;
    border-top: 1px solid #ADE299;
    margin-bottom: 4em;
}

.articleContainer .e-rte-image,
.articleContainer .e-imgcenter,
.articleContainer .e-rte-image.e-imgLeft {
    cursor: default
}

@media screen and (max-width: 48em) {
    .articleContainer div {
        width: 95%;
    }

}