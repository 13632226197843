.scrollContainer {
    height: 500px;
    overflow-y: auto;
	scrollbar-color: #033F63 #FEFEFE;
	scrollbar-width: auto;
}

.scrollContainer > li > button {
    background-color: #FEFEFE;
    border: 0;
    transition: background-color 0.4s ease-out;
}

.scrollContainer h2 {
    margin: 0;
    text-align: left;
}

.scrollContainer button:hover {
    background-color: #BDE8AD;
}

.scrollContainer::-webkit-scrollbar {
    width: 1em;
    background-color: #033F63;
    border-radius: 4px;
}

.scrollContainer::-webkit-scrollbar-track {
    background-color: #FEFEFE;
    border: 2px solid #033F63;
    border-radius: 4px;
    position: relative;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background-color: #033F63;
    width: 0.75em;
    border-radius: 4px;
}

.scrollContainer::-webkit-scrollbar-thumb:hover {
    background-color: #023250;
    width: 0.75em;
    border-radius: 4px;
}

@media screen and (max-width: 48em) {
    .scrollContainer {
        height: initial;
    }
}