.faqContainer {
    width: 65%;
    margin: 0 auto;
}

.faqContainer h2 {
    margin-bottom: 0;
    display: block;
}

.faqButtons {
    text-align: center;
}

.faqButtons > button {
    font-size: 1.5625rem;
    color: #033F63;
    font-family: 'Architects Daughter', cursive;   
    margin: 1em 2em;
    background: none;
    border: none;
}

.faqButtons > button > div > span > svg {
    padding-right: 0.5em;
}

.faqItemsContainer {
    width: 100%;
    margin: 0 auto;
}

.faqItemsContainer ul {
    padding: 0;
    margin-bottom: 3em;
}

.faqSearch form {
    display: flex;
    width: 100%;
    margin-bottom: 2em;
}

.faqSearch input {
    width: 100%;
    font-size: 1.3125rem;
    font-family: 'Montserrat', sans-serif;
    border: 3px solid #033F63;
    padding: 0.5em;
    border-radius: 255px 0px 0px 15px/15px 225px 15px 255px;
}

.faqSearch button {
    padding: 0.5em 1em;
    border-radius: 0 15px 255px 0px/15px 225px 15px 255px;
    border: 3px solid #033F63;
    border-left: 0.1px solid #033F63;
    background-color: #BDE8AD;
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

.faqItemsContainer>p {
    margin-bottom: 5em;
}

@media screen and (min-width: 48em) and (max-width: 72em) {
    .faqContainer {
        width: 75%;
    }
    
    .faqButtons > button {
        margin: 1em 0.8em;
    }
}

@media screen and (max-width: 48em) {

    .faqContainer {
        width: 100%;
    }

    .faqSearch h2 {
        display: none;
    }
    
    .faqButtons {
        display: flex;
    }

    .faqButtons button {
        margin: 0.25em;
        flex-basis: 33%;
    }

    .faqButtons>button>span>svg {
        padding-right: 0;
    }

    .faqButtons button span {
        font-size: 1.3rem;
        text-align: center;
    }

    .faqButtons button svg {
        width: 100%;
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    .faqSearch input {
        font-size: 1.2rem;
    };

}