.videoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.video {
    display: flex;
    align-items: center;
}

.video video {
    width: inherit;
    height: 100%;
    object-fit: fill;
    margin: 0;
    position: relative;
    top: -10%;
}

.video track {
    font-family: 'Montserrat', sans-serif;
    background-color: #033F63;
}

.video {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    cursor: pointer;
}

.video button {
    position: absolute;
    top: 50%; 
    left: 50%;
    padding: 0;
    border: 0;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 2;
}

.home-video {
    position: relative;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    border: 2px solid #444444;
    background-color: #FEFEFE;
}

.home-video::before {
    display: block;
    position: absolute;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #033F63;
    z-index: -1;
    transform: rotate(-4deg);
}

.video button.hide,
.controlsToggle {
    display: none;
}

.videoControls button {
    background-color: transparent;
    border: 0;
    padding: 0 0.5em;
    margin: 0 2em;
}

.videoControls svg{
    font-size: 2.5rem;
    color: #033F63;
}

@media screen and (max-width: 48em) {
    .video img {
        width: calc(50% + 2vw);
    }

    .home-video {
        margin-bottom: 2em;
    }

    .videoControls button {
        margin: 0 1em; 
    }

    .videoControls svg {
        font-size: 2rem;
    }
}

@media screen and (max-width: 20em) {
    .videoControls button {
        margin: 0 0.75em;
    }

}