.loadingIcon {
    text-align: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.loadingIcon h2 {
    font-size: 2rem;
    text-align: center;
    flex-basis: 100%;
}

@media screen and (max-width: 48em) {
    .loadingIcon {
        height: auto;
    }
    
    
    .loadingIcon img {
        width: 50%;
        height: auto;
    }
}