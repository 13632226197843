.teleContainer {
    min-height: 300px;
    text-align: center;
}

.teleSearch {
    display: inline-block;
    width: 55%;
    border: 2px solid #444444;
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    margin-right: 2em;
}

.teleSearch input,
.nameButton {
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    border: 0;
    padding: 0.5em;
    width: 90%;
}


.teleSearch .nameButton {
    display: inline-block;
    text-align: left;
    margin: 0;
    padding: 0;
}
.teleSearch input:focus-within {
    outline: none;
}

.teleSearch button {
    padding: 0.5em 1em;
    border: 0;
    background: none;
}

.teleSearch button:focus-within {
    transform: scale(1.1);   
    outline: none;
}

.disabledButton,
.enabledButton {
    border-radius: 3px;
    border: 2px solid #444444;
    font-weight:400;
    display: inline;
    padding: 0.5em 3em;
    font-size: 1.25rem;
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    color: #444444;
}

.enabledButton {
    text-decoration: none;
}

.enabledButton:hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
}

.disabledButton:disabled {
    background: #EAEAEA;
    color: #858585;
    font-family: inherit;
}

.providerList {
    width: 55%;
    border: 2px solid #444444;
    border-radius: 3px;
    margin-top: 2em;
    display: inline-block;
        margin-right: 2em;
}

.providerList ul {
    padding: 0;
    margin: 0;
    overflow: auto;
    max-height: 300px;
}

.provider {
    font-family: inherit;
    font-size: 1.25rem;
    width: 100%;
    text-align: left;
    padding: 0.5em 0.5em;
    background-color: #FEFEFE;
    border: 0;
    border-bottom:1px solid #444444
}

.provider:hover {
    background-color: #F5FAFD;
}

.provider:focus-within {
    outline: 2px solid #444444;
    border-bottom: 2px solid #444444;
    background-color: #F5FAFD;
    font-weight: bold;
}

.teleContainer ul::-webkit-scrollbar {
    width: 1rem;
    background-color: #033F63;
    border-radius: 3px;
}

.teleContainer ul::-webkit-scrollbar-track {
    background-color: #FEFEFE;
    border: 1px solid #033F63;
    border-radius: 3px;
    position: relative;
}

.teleContainer ul::-webkit-scrollbar-thumb {
    background-color: #033F63;
    width: 0.75em;
    border-radius: 3px;
}

.teleContainer ul::-webkit-scrollbar-thumb:hover {
    background-color: #023250;
    width: 0.75em;
    border-radius: 3px;
}

.desktop {
    display: inline-block;
}

.mobile {
    display: none;
}

@media screen and (max-width: 48em) {

    .teleSearch {
        width: 100%;
    }

    .teleSearch input {
        width: 88%;
        font-size: 1.2rem;
    }

    .teleSearch button {
        padding: 0.5em;
    }

    .disabledButton, .enabledButton {
        width: 100%;
        margin: 2em 0;
        display: block;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .providerList {
        width: 100%;
    }

    .teleContainer {
        margin-top: 2rem;
    }

}

@media screen and (min-width: 75em) {
	.contentPara {
		margin-right: 100px;
	}
}

@media screen and (min-width: 98em) {
	.contentPara {
		margin-right: unset;
	}
}