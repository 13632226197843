.notFound-container {
    text-align: center;
    margin-bottom: 4em;
}

.notFound-container h1 {
    font-size: 6rem;
    margin-bottom: 0.05em;
    margin-top: 0.25em;
}

.notFound-container p {
    font-size: 2.5rem;
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    margin: 0.15em;
}

.notFound-container p:nth-child(odd) {
    font-size: 1.5rem;
    font-family: inherit;
}

.notFound-container img {
    width: 25%;
    margin: 2em;
}

.notFound-container button {
    margin-top: 2em;
    border-radius: 3px;
    border: 2px solid #444444;
    font-weight: 400;
    display: inline;
    padding: 0.5em 4em;
    font-size: 1.25rem;
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    color: #444444;
    font-family: inherit;
}

.hide {
    display: none;
}

@media screen and (max-width: 48em) {

    .notFound-container h1 {
        font-size: 3.5rem;
    }

    .notFound-container p {
        font-size: 1.5rem;
    }

    .notFound-container p:last-child {
        font-size: 1rem;
    }

}