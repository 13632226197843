.deleteModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #444444;
    width: 325px;
    background-color: #fefefe;
    padding: 0.5em;
    border-radius: 5px;
    z-index: 101;
    text-align: center;
    box-shadow: 4px 4px 8px #242424;
}

.deleteModal div {
    text-align: right;
    padding: 0;
    width: 100%;
}

.deleteModal div:first-child > button {
    background-color: #fefefe;
    border: 0;
    color: #033F63;
    padding: 0.25em 0.5em;
    font-size: 1.1rem;
}


.deleteModal > svg {
    font-size: 3rem;
    color: #033F63;
    margin: 0 auto;
}

.btnContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;
}

.btnContainer > button {
    border: 1px solid #033F63;
    border-radius: 3px;
    padding: 0.5em 2.2em;
    font-family: inherit;
    color: #033F63;
    font-size: 1.1rem;
    transition: all 0.5s ease-out;
}

.btnContainer>button:first-child {
    background-color: #fefefe;
}

.btnContainer>button:first-child:hover {
    background-color: #033F63;
    color: #fefefe;
}

.btnContainer>button:nth-child(2) {
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    color: #033F63;
}

.btnContainer>button:nth-child(2):hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
}