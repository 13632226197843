.adminHome nav {
    display: flex;
    justify-content: center;
    margin-top: 4em;
}

.adminHome nav > div {
    position: relative;
    text-align: center;
    border-radius: 3px;
    border: 2px solid #033F63;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    margin: 1em;
    width: 27%;    
    background-color: #fefefe;
}

.adminHome nav > div > p {
    color:#033F63;
}

.adminHome svg {
    padding: 0 0.25em;
}

.adminHome nav>div::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    transform: rotate(-5deg);
    z-index: -1;
    background-color: #033F63;
}

.adminHome nav>div:nth-child(2):after {
    background-color: #4A8235;
}

.adminHome nav>div:nth-child(3):after {
background-color: #F9921D;
}

.adminHome p {
    font-weight: 400;
}

.adminHome ul {
    padding: 0.5em;
    text-align: left;
}

.adminHome li {
    display: block;
    padding: 1em;
}

.adminHome a {
    text-decoration: none;
    border-bottom-right-radius: 225px 10px;
    border-top-right-radius: 360px 250px;
    border-bottom-left-radius: 360px 30px;
    font-weight: 400;
}

.adminHome a:hover {
    border-bottom: 3px solid #033F63;
}

@media screen and (max-width: 62.25em) {
    .adminHome nav > div {
        width: 32%;
    }
}

@media screen and (max-width: 48em) {
    .adminHome nav {
        margin-top: 2em;
    }
    
    .adminHome nav  p {
        font-size: 1.3rem;
        margin-bottom: 0;
    }
    
    .adminHome nav > div {
        width: 100%;
    }

    .adminHome nav {
        flex-wrap: wrap;
    }
}