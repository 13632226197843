
@keyframes titleScroll {
	0% {
		transform: translateY(-0.46em);
	}

	40% {
		transform: translateY(-0.46em);
	}

	50% {
		transform: translateY(-2.46em);
	}

	90% {
		transform: translateY(-2.46em);
	}

	100% {
		transform: translateY(-4.46em);
	}
}

h2 {
	margin-bottom: 0.2em;
    font-size: 2.1875rem;
}

.row > * {
	margin-top: 3em;
}
.row h3 {
	font-weight: bold;
	font-family: 'Montserrat', sans-serif;
}

.rotatingTitle {
	line-height: 1.1;
}
.rotatingTitle > div {
	display: inline-block;
	overflow: hidden;
	height: 1.1em;
	position: relative;
	top: 0.25em;
	
}
.rotatingTitle > div > div {
	height: 6em;
	transform: translateY(-0.46em);
	animation: titleScroll 6s forwards;
}
.rotatingTitle > div > div span {
	display: block;
	line-height: 2;
}

.topRow > *:nth-child(2) {
	margin-bottom: -2em;
}

.topRow img {
	max-width: 400px;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.teamLink{
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    font-size: 1.2rem;
    padding: 0.35em 3em;
    border-radius: 3px;
    border: 1px solid #033F63;
    text-decoration: none;
    color: #033F63;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.section {
	margin-bottom: 4em;
}

@media screen and (min-width: 32em) {
	.teamLink{
		width: auto;
	}
}

@media screen and (min-width: 52em) {
	.careersWrapper {
		position: relative;
		overflow: hidden;
	}
	.rotatingTitle {
		position: absolute;
		top: 0;
		left: 0;
	}
	.topRow {
		display: flex;
		justify-content: space-between;
		margin-top: 6em;
	}
	.topRow > *:first-child {
		flex: 0 0 60%;
	}
	.topRow > *:nth-child(2) {
		flex: 0 0 35%;
		margin-top: -2em;
	}
	.topRow img {
		margin-right: 30%;
		max-width: unset;
		margin-left: 0;
	}
	.row {
		display: flex;
		justify-content: space-between;
	}
	.row > * {
		flex: 0 0 30%;
		margin-top: 1em;
	}
}