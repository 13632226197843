.blog-card-container {
    height: 29.572em;
    width: 26.45em;
    padding: 2em;
    border-top-left-radius: 255px 25px;
    border-top-right-radius: 35px 255px;
    border-bottom-right-radius: 225px 35px;
    border-bottom-left-radius: 45px 255px;
    border: 2px solid #444444;
    background-color: #FEFEFE;
    margin-bottom: 5em;
    overflow: hidden;
}

.blog-card-container::after {
    content: '';
    width: inherit;
    height: inherit;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    background-color: #033F63;
    transform: rotate(-6deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-card-container a {
    text-decoration: none;
}

.blog-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 3px;
}

.blog-card-title {
    font-size: 2.1875rem;
    text-align: center;
    margin: 0.5em 0 0 0;
}

.blog-card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media screen and (max-width: 48em) {
    
    .blog-card-container {
        width: 95%;
        margin: 0 auto;
        padding: 0 1.5em;
    }

    .blog-card-container::after {
        width: 97%;
        height: 100%;
        left: 1%;
        transform: rotate(-3deg);
    }

    .blog-card-image {
        width: 100%;
        height: 200px;
        margin-top: 1.5em;
    }

    .blog-card-title {
        margin: 0.5em 0 0.25em;
    }

    .blog-card-text {
        font-size: 1rem;
    }

    .blog-card-text p {
        margin-top: 0.5em;
    }

    .visible {
        position: relative;
    }

    .invisible {
        display: none;
    }

    .horizontalLayout {
        display: flex;
        margin-bottom: 1.5em;
        height: auto;
        align-items: center;
        padding: 1em 0;
        width: 95vw;
    }

    .horizontalLayout::after {
        width: 97%;
        height: 85%;
        left: 0;
        top: 2.5%;
    }

    .horizontalLayout > div {
        padding-left: 1em;
    }
    
    .horizontalLayout h2 {
        font-size: 1.45rem;
        margin: 0;
        text-align: left;
    }

    .horizontalLayout img {
        flex-basis: 90%;
        height: 150px;
        margin-left: 1em;
        margin-top: 0;
    }

    .horizontalLayout>div {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .horizontalLayout p {
        font-size: 0.8rem;
        margin-top: 0.5em;
    }

}

@media screen and (max-width: 36em) {

.horizontalLayout .blog-card-text {
        display: none;
    }

    .horizontalLayout h2 {
        flex-basis: 100%;
    }

    .horizontalLayout img {
        height: 100px;
    }
}


@media screen and (max-width: 23.4375em) {
    .smallMobileHorizontal {
            display: flex;
            margin-bottom: 1.5em;
            height: auto;
            align-items: center;
            padding: 1em 0;
            width: 95vw;
        }
    
        .smallMobileHorizontal::after {
            width: 97%;
            height: 85%;
            left: 0;
            top: 2.5%;
        }
    
        .smallMobileHorizontal>div {
            padding-left: 1em;
        }
    
        .smallMobileHorizontal h2 {
            font-size: 1.45rem;
            margin: 0;
            text-align: left;
        }
    
        .smallMobileHorizontal img {
            flex-basis: 90%;
            height: 150px;
            margin-left: 1em;
            margin-top: 0;
        }
    
        .smallMobileHorizontal>div {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    
        .smallMobileHorizontal p {
            font-size: 0.8rem;
            margin-top: 0.5em;
        }

        .smallMobileHorizontal .blog-card-text {
            display: none;
        }

        .smallMobileHorizontal h2 {
            flex-basis: 100%;
        }

        .smallMobileHorizontal img {
            height: 100px;
        }
}