.grid {
    margin-bottom: 4em;
    display: grid;
    grid-template-columns: 30% 60%;
    grid-template-rows: auto;
    grid-template-areas:
        "title location-buttons"
        "search map"
        "scrollcontainer map";
}



.locations-page>div>button {
    font-size: 2.1875rem;
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    background-color: transparent;
    border: 0;
    border-radius: 255px 15px 255px 15px/15px 225px 15px 255px;
    margin-right: 2em;
    align-self: center;
    cursor: pointer;
}

.searchBar form {
    display: flex;
    width: fit-content;
}

.searchBar input {
    font-size: 1.3125rem;
    font-family: 'Montserrat', sans-serif;
    border: 3px solid #033F63;
    padding: 0.5em;
    border-radius: 255px 0px 0px 15px/15px 225px 15px 255px;
}

.searchBar button {
    padding: 0.5em 1em;
    border-radius: 0 15px 255px 0px/15px 225px 15px 255px;
    border: 3px solid #033F63;
    border-left: 0.1px solid #033F63;
    background-color: #BDE8AD;
}

.locationsSidebar {
    width: 331px;
}

.locationsSidebar li {
    margin-bottom: 1.25em;
}

.locationsSidebar li h2 {
    font-size: 1.875rem;
}

.locationsSidebar p {
    line-height: 1;
}

.locations-title {
    grid-area: title;
}

.locations-buttons {
    grid-area: location-buttons;
}

.searchBar {
    grid-area: search;
}

.locationsSidebar {
    grid-area: scrollcontainer;
    margin-right: 5em;
}

@media screen and (min-width: 62em) and (max-width: 75em) {
    .grid {
        grid-template-columns: 40% 60%;
    }

    .searchBar form {
        width: 85%;
    }

    .searchBar input {
        width: 100%;
    }

    .locationsSidebar {
        width: 85%;
    }
}


@media screen and (min-width: 48em) and (max-width: 62em) {

    .locations-page>div>button {
        font-size: 1.9rem;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
        grid-template-areas:
            "title location-buttons"
            "search search"
            "scrollcontainer map"
    }

    .locations-page>div>button {
        margin-right: 1em;
    }

    .locationsSidebar {
        margin-right: 2em;
    }

    .searchBar input {
        width: 100%;
    }

    .searchBar form {
        width: 100%;
        margin-bottom: 2em;
    }

}

@media screen and (max-width: 48em) {
    .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "title"
            "search"
            "map"
            "location-buttons"
            "scrollcontainer"
    }

    .searchBar input {
        font-size: 1rem;
        width: 100%;
    }

    .searchbar svg {
        font-size: 1.3rem;
    }

    .searchBar form {
        width: 100%;
        margin-bottom: 2em;
    }

    .locations-page > div > button {
        font-size: 1.5625rem;
        margin-right: 1em;
    }

    .locationsSidebar {
        margin-right: 0;
        width: 100%
    }

    .locationsSidebar li h2 {
        font-size: 1.5625rem
    }

}

