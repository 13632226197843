



.icons {
	display: flex;
	justify-content: center;
	column-gap: 1.5em;
	row-gap: 1em;
	flex: 0 0 100%;
	flex-wrap: wrap;
	margin-top: 1em;
	margin-bottom: 2em;
}
.first, .second, .third, .fourth {
	flex: 0 0 auto;
	font-size: 0.8rem;
}
.first img, .second img, .third img, .fourth img {
	display: block;
	margin: 0 auto;
}
.first a, .second a, .third a, .fourth a {
	text-decoration: none;
	display: inline-block;
}
.first a:hover, .second a:hover, .third a:hover, .fourth a:hover {
	font-weight: bold;
}
.first {
	order: 2;
}
.second {
	order: 3;
}
.third {
	order: 5;
}
.fourth {
	order: 6;
}
.logo {
	order: 1;
	flex: 0 0 100%;
	text-align: center;
}
.logo div {
	margin-top: 1em;
}


.socialmedia {
	margin-bottom: 1em;
}
.socialmedia a:first-child {
    padding-right: 1.5em;
}

.anewLogo {
    height: 135px;
}
.subfooter {
	text-align: center;
	margin-bottom: 1em;
}
.copy {
	margin-bottom: 1em;
}
.designed a {
	text-decoration: none;
}

@media screen and (min-width: 28em) {
	.icons {
		column-gap: 2em;
	}
}

@media screen and (min-width: 48em) {

	.icons {
		align-items: center;
	}
	.logo {
		order: 4;
		flex: 0 0 195px;
		text-align: center;
	}
	.subfooter {
		display: flex;
		column-gap: 2em;
		justify-content: center;
		align-items: center;
	}

	.socialmedia {
		flex: 0 0 95px;
		order: 2;
		margin-bottom: 0;
	}
	.copy {
		order: 1;
		margin-bottom: 0;
	}
	.designed {
		order: 3;
	}
}


