.howHelpContainer {
    display: flex;
    flex-wrap: wrap;
}

.howHelpContainer div {
    flex: 0 0 50%;
}

.howHelpContainer div:nth-child(even) {
    text-align: start;
}

.howHelpContainer div:nth-child(odd) {
    text-align: end;
}

.howHelpContainer a {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 2em;
    margin: 1em 3em;
    background-color: #FEFEFE;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    width: 350px;
    position: relative;
    border: 1px solid #023250;
}
.howHelpContainer a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    transform: rotate(-5deg);
    z-index: -1;
	transition: all 0.3s ease-in-out;
}
.howHelpContainer a:hover:before {
	transform: rotate(5deg);
}

@media screen and (min-width: 48em) and (max-width: 62em) {
    .howHelpContainer {
        justify-content: center;
    }
    
    .howHelpContainer div {
        flex: 0 0 45%;
    }
    
    .howHelpContainer a {
        margin: 1em 0.5em;
    }
}

@media screen and (max-width: 48em) {

    .howHelpContainer div {
        flex: 0 0 100%;
    }

    .howHelpContainer div:last-child {
        margin-bottom: 3em;
    }

    .howHelpContainer a {
        width: 100%;
        margin: 1.5em 0;
        padding: 1em;
    }

    .howHelpContainer a h2 {
        font-size: 1.45rem;
    }

}

@media screen and (max-width: 20em) {
    .title h1 {
        font-size: 2rem;
    }
}