.Backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.4s ease-out;
}

.BackdropOpen {
    display: block
}

.BackdropClosed {
    display: none;
}