body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    background-color: #FEFEFE;
	min-width: 350px;
}
img {
	max-width: 100%;
	height: auto;
}
h1,
h2,
h3 {
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    font-weight: 400;
}

h1 {
    font-size: 2.5rem;
}
a, a:link, a:visited {
	color: #444444;
}
p {
    font-size: 1.125rem;
    color: #444444;
}

ul:not(.bulleted) {
    list-style: none;
}

button {
    cursor: pointer;
}

main {
    min-height: 78vh;
}

input.invalid {
    border-left: 3px solid #F33 !important;
}

progress {
	border-radius: 5px; 
	height: 1em;
	overflow: hidden;
	cursor: pointer;
  }
  progress::-webkit-progress-bar {
	background-color: #DDD;
  }
  progress::-webkit-progress-value {
	background-color: #003363;
  }
  progress::-moz-progress-bar {
	background-color: #003363;
  }

::cue {
    background-color: #444444;
    color: #fefefe;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
}

.mtop {
	margin-top: 1em;
}
.mbot {
	margin-bottom: 1em;
}
.mleft {
	margin-left: 1em;
}
.mright {
	margin-right: 1em;
}

.mtop-large {
	margin-top: 2em;
}
.mbot-large {
	margin-bottom: 2em;
}
.mleft-large {
	margin-left: 2em;
}
.mright-large {
	margin-right: 2em;
}

.mtop-none {
	margin-top: 0;
}
.mbot-none {
	margin-bottom: 0;
}
.text-center {
	text-align: center;
}

.validation-errors {
    margin: 0;
    padding: 0;
    list-style: none;
}

.validation-errors li {
    text-align: left;
    color: #F33;
    font-weight: 400;
}

.success {
    color: #4A8235;
}

.tree-svg {
    position: absolute;
    top: -0.5em;
    right: 0;
	height: 196px;
	z-index: -1;
}

.map-side {
    position: relative;
    height: 600px;
    margin-bottom: 2em;
    grid-area: map;
}

#map {
    height: inherit;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    border: 4px solid #033F63;
}

.map-side::after {
    content: "";
    width: 98%;
    height: 100%;
    background-color: #033F63;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    transform: rotate(-4deg);
    position: absolute;
    z-index: -1;
    grid-area: map;
    top: 0;
    left: 1%;
}

.open {
    transform: rotate(180deg);
    transition: all 0.3s ease-out;
}

.close {
    transform: rotate(0deg);
    transition: all 0.5s ease-out;
}

.open-content {
    max-height: inherit;
}

.faq-items-list {
    list-style-type: disc;
    padding: inherit;
}

.translate-enter {
    transform: translateX(-100%);
}

.translate-enter-active {
    transform: translateX(0);
    transition: all 1000ms ease-out;
}

.translate-exit {
    transform: translateX(0);
}

.translate-exit-active {
    transform: translateX(-100%);
    transition: all 1000ms ease-out
}

.hideForm {
    display: none;
}

.underline,
.active>span {
    border-bottom: 3px solid #033F63;
    border-bottom-right-radius: 225px 10px;
    border-top-right-radius: 360px 250px;
    border-bottom-left-radius: 360px 30px;
    position: relative;
}

/* ************************ */
/*Footer */
/* ************************ */
footer {
    border-top: #ade29966 2px solid;
    margin-top: 0.8em;
}

footer p:not(.normal) {
    font-size: 0.75rem;
}
footer p.normal {
	font-size: 0.9rem;
}

footer a {
    padding: 0;
}

.video-controls-container {
    border: solid 1.5px #001F31;
    width: 36px;
    position: absolute;
    height: 36px;
    border-radius: 50%;
	top: 0;
    right: 0;
    background-color: transparent;
    cursor: pointer;
}

.video-controls-container:focus {
    border: solid 2px #001F31;
}

.play-button {
    position: absolute;
    box-sizing: border-box;
    background-color: #FEFEFE;
    height: 15px;
    width: 15px;
    border-style: solid;
    border-width: 8px 0px 8px 16px;
    border-color: transparent transparent transparent #001F31;
    border-radius: 3px;
    top: 22%;
    left: 30%;
    z-index: 0;
}

.pause-button {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #fefefe00;
    border-style: double;
    border-width: 0 0 0 10px;
    top: 25%;
    left: 35%;
}

.e-rte-image,
.e-imgcenter,
.e-rte-image.e-imgLeft,
.e-rte-image.e-imgcenter {
    cursor: default;
}

/* ******************************************************* */
/* Alternating Background Colors */

.blue-background {
    background-color: #033F63;
}

.blue::after {
    background-color: #033F63;
}

.blue::before {
    background-color: #033F63;
}

.orange-background {
    background-color: #F9921D;
}

.orange::after {
    background-color: #F9921D;
}

.orange::before {
    background-color: #F9921D;
}

.gold-background {
    background-color: #F9D976;
}

.gold::after {
    background-color: #F9D976;
}

.gold::before {
    background-color: #F9D976;
}

.dark-green-background {
    background-color: #4A8235;
}

.dark-green::after {
    background-color: #4A8235;
}

.dark-green::before {
    background-color: #4A8235;
}

.light-green::before {
    background-color: #ADE299;
}

.light-green::after {
    background-color: #ADE299;
}

/* ************** Transition **************** */

.fade-in {
    animation: 1s ease-in-out both fade-in;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rock-n-roll {
	0% {
		transform: rotate(0);
	}

	25% {
		transform: rotate(4deg);
	}

	75% {
		transform: rotate(-4deg);
	}

	100% {
		transform: rotate(0);
	}
}

.primary-cta {
	animation-duration: 0.7s;
	transform-origin: center;
}
.primary-cta:hover {
	animation-name: rock-n-roll;
	animation-timing-function: ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .fade-in {
        animation: none !important;
    }
}

/* *********************************** */

@media screen and (max-width: 48em) {
    .container-width {
        width: 100%;
        padding: 0 1em;
    }

    p {
        font-size: 1rem;
    }

    .map-side {
        height: calc(250px + (20vw));
        width: 100%;
    }

    #map {
        margin-left: 0;
    }

    h1,
    h2,
    h3 {
        font-family: 'Architects Daughter', cursive;
        color: #033F63;
        font-weight: 400;
    }

    h1 {
        font-size: 2.1875rem;
    }

    p {
        font-size: 1rem
    }

}

@media screen and (min-width: 48em) and (max-width: 62em) {

    .map-side {
        height: 500px;
        width: 100%;
    }

	.tree-svg {
        height: 350px
    }

}

@media screen and (min-width: 62em) {
    .tree-svg {
        height: 530px;
    }
}

@media screen and (min-width: 75em) {
    .tree-svg {
            height: 650px;
    }
    
}

