.input {
    width: 100%;
    padding: 0.8em;
    box-sizing: border-box;
}

.input label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.4em;
    font-size: 1.1rem;
}

.input input,
.input textarea {
    outline: none;
    border: 1px solid #033F63;
    font: inherit;
    padding: 0.4em 0.8em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 1.1rem;
}

.input input:focus,
.input textarea:focus {
    outline: 1px solid #033F63;    background-color: #F5FAFD;
}

.input input[type='file'] {
    border: none;
}

.input select:focus {
    outline: 1px solid #033F63;
    background-color: #F5FAFD;
}

.input select {
    border: 1px solid #033F63;
}