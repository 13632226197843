.locationForm {
    width: 75%;
    margin: 0 auto;
}

.locationForm select {
    font-size: 1.1rem;
    font-family: inherit;
    padding: 0.25em;
    border-radius: 5px;
}



.locationForm button, .locationForm a {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    margin: 0.8em;
    padding: 0.25em 0.5em;
    transition: all 0.5s ease-out;
    border-radius: 3px;
}

.locationForm select+button {
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    color:#033F63;
    font-style: italic;
}

.locationForm select+button:hover {
    background-color: transparent;
    font-style: italic;
    text-decoration: underline;
    color:#033F63;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnContainer button {
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    color: #033F63;
    padding: 0.5em 2em;
}

.btnContainer button:hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
}

.btnContainer a {
    border: 1px solid #033F63;
    text-decoration: none;
    padding: 0.5em 2em;
}

.btnContainer a:hover {
    border-radius: 5px;
    background-color: #033F63;
    color: #fefefe;
}

@media screen and (max-width: 48em) {
    .locationForm {
        width: 100%;
    }

    .btnContainer {
        padding: 0 0.8em;
    }
    
    .btnContainer button {
        margin-left: 0;
        margin-right: 0;
        font-size: 1rem;
    }

    .btnContainer a {
        margin-left: 0;
        font-size: 1rem;
    }
}

@media screen and (max-width: 23.4375em) {
    .btnContainer button {
        padding: 0.5em;
    }
}