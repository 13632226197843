
.cta-wrapper {
	margin-bottom: 2em;
}
.cta {
	text-align: center;
}
.cta i {
	margin-right: 1em;
}
.instantAccess {
	background: linear-gradient(180deg,#f9d976,#ffbf3e);
    border: 1px solid #033f63;
    border-radius: 3px;
    color: #033f63;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.35em 3em;
    text-align: center;
    text-decoration: none;
}

#aboutAnewSection {
    margin-top: 2.18em;
}

.home-video {
    width: 52.813em;
    height: 37.5em;
    position: relative;
    border: 5px solid #490077;
}

.anew-bio {
    margin-left: 2.1875em;
    flex-basis: 30%;
}

.explanation-heading {
    margin-bottom: 0.2em;
    font-size: 2.1875rem;
}

.explanation-heading:not(:first-child) {
    margin-top: 1.1em;
}

.explanation-heading+p {
    margin-top: 0;
}

.chevron-container-margins {
    margin: 3.75em auto;
}

.chevron-down {
    display: block;
    position: relative;
    height: 60px;
    width: 46px;
    margin: 0 auto;
    background-color: transparent;
    border: 0;
}

.chevron-down:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    width: 23px;
    background: #444444;
    transform: skew(0deg, -25deg);
    border-radius: 25%;
}

.chevron-down:after {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    height: 2px;
    width: 23px;
    background: #444444;
    transform: skew(0deg, 25deg);
    border-radius: 25%;
}


.teleHealthLink{
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
    font-size: 1.4rem;
    padding: 0.35em 3em;
    border-radius: 3px;
    border: 1px solid #033F63;
    text-decoration: none;
    color: #033F63;
    display: inline-block;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.telehealth-section, .podcast-section {
	margin-top: 2em;
}

.telehealth-section > *:first-child img,
.podcast-section > *:first-child img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.articleTitle {
    display: none;
}

.articleTitle + div a {
    text-decoration: none;
}

.articleTitle + p {
    text-align: center;
    font-size: 1.2rem;
}

.see-all-container {
    margin: 2.188em;
}

.see-all-button {
    background-image: linear-gradient(#033F63 100%, #001F31 100%);
    padding: 0.625em;
    line-height: 3;
    font-size: 1.25rem;
    color: #FEFEFE;
    text-decoration: none;
    border-radius: 5px;
}

@media screen and (min-width: 36em) {
	.telehealth-heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media screen and (min-width: 48em) {
	.explanation-heading {
		margin-top: 0;
	}
	.cta-wrapper {
		display: flex;
		align-items: center;
	}
	.cta-text {
		flex: 0 0 60%;
	}
	.cta-wrapper p {
		margin-bottom: 0;
	}
	.cta {
		margin-left: 2.1875em;
		flex: 0 0 30%;
		text-align: left;
	}
}

@media screen and (min-width: 55em) {
	.telehealth-section, .podcast-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.podcast-section {
		flex-direction: row-reverse;
	}
	.telehealth-section > *:first-child,
	.podcast-section > *:first-child {
		flex: 0 0 32%;
	}
	.telehealth-section > *:nth-child(2)
	.podcast-section > *:nth-child(2) {
		flex: 0 0 64%;
	}
}

@media screen and (max-width: 48em) {

    .explanation-heading:not(:first-child) {
        margin-top: 0.5em;
    }

    .explanation-heading {
        margin-top: 0.5em;
    }

    .home-video {
        width: 100%;
        height: 350px;
    }

    .anew-bio {
        margin: 1em 0;
        flex-basis: 100%;
    }

    .chevron-container-margins {
        margin: 0;
    }

    .articleTitle {
        display: block;
        font-size: 2.1875rem;
    }

    .teleHealthLink {
        margin-top: 1em;
    };

    .see-all-container {
        margin: 4em 0 4em;
    }

    .see-all-button {
        width: 100%;
        display: block;
        text-align: center;
        line-height: 1.5;
        background-image: none;
        background-color: #FEFEFE;
        border: 2px solid #033F63;
        color: #033F63;
        font-weight: 500;
        transition: all 0.5s ease-out;
    }

    .see-all-button:hover {
        background-color: #033F63;
        color: #FEFEFE;
    }

    .see-all-button:focus{
        background-color: #033F63;
        color: #FEFEFE;
    };

}