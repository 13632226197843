.topRow {
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.topRow {
    font-size: 1.2rem;
}

.topRow a {
    text-decoration: none;
    color: #033F63;
    font-weight: 400;
}

.topRow form {
    border: 2px solid #033F63;
    display: flex;
    border-radius: 255px 15px 255px 15px/15px 225px 15px 255px;}

.topRow input {
    height: 100%;
    border: 0;
    font-size: 1.2rem;
    padding: 0.2em 0.25em;
    color: #444444;
    font-family: inherit;
}

.topRow button {
    background-color: #BDE8AD;
    color: #033F63;
    border: 0;
    border-left: 2px solid #033F63;
    font-size: 1.2rem;
}

.topRow input:focus {
    background-color: #F5FAFD;
    outline: none;
}



.topRow a:hover {
    text-decoration: underline;
}

.articlesTable {
    width: 95%;
    border-collapse: collapse;
    color: #444444;
}

.articlesTable thead {
    border-bottom: 2px solid #033F63;
    color: #033F63
}

.articlesTable thead th {
    text-align: left;
    cursor: pointer;
}

.articlesTable th:last-child {
    cursor: default;
}

.articlesTable th[id="link"],
.articlesTable th[id="roomUrl"] {
    cursor: default;
}

.articlesTable tbody td {
    padding: 1em 0;
}


.articlesTable tbody tr td:last-child {
    display: flex;
    justify-content: space-around;
}

.articlesTable button {
    background-color: transparent;
    border: none;
}

.articlesTable tbody tr {
    border-bottom: 1px solid #033F63;
}

.articlesTable tbody tr:last-child {
    border-bottom: 0;
}

.articlesTable  a {
    color: inherit;
}



@media screen and (max-width: 62.25em) {
    .desktopHide {
        display: none
    }
}

@media screen and (max-width: 48em) {
    .hideLink {
        display: none;
    }

    .topRow {
        flex-wrap: wrap
    }


}

@media screen and (max-width: 32.5em) {
    .mobileHide {
        display: none;
    }

    .topRow form{
        margin-top: 1em;
        width: 100%;
    }
    
    .topRow input {
        width: 100%;
    }
}