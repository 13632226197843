.enter {
    opacity: 0
}

.enterActive {
    opacity: 1;
    transition: all 600ms ease-out;
}

.exit {
   opacity: 1;
}

.exitActive {
   opacity: 0;
   transition: all 600ms ease-out;
}

.transitionWrapper {
    position: relative;
    height: 100vh;
}

.transitionSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}