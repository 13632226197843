.accountContainer h1 {
    width: 75%;
    margin: 0.8em auto;
}

.loginForm {
    width: 75%;
    margin: 0 auto;
}

.logInImage {
    width: 20%;
    margin: 0 auto;
    text-align: center;
}

.logButtonContainer {
    margin: 1em 0.8em;
    display: flex;
    justify-content: space-between;
}

.logButtonContainer a {
    font-size: 1.25rem;
    text-decoration: none;
    border: 1px solid #033F63;
    padding: 0.25em 2em;
    border-radius: 3px;
    color: #033F63;
    transition: all 0.5s ease-out;
}

.logButtonContainer a:hover {
    background-color: #033F63;
    color: #fefefe;
}

.logButtonContainer button {
    font-size: 1.25rem;
    font-family: inherit;
    background: linear-gradient(180deg, #F9D976 0%, #FFBF3E 100%);
        color: #033F63;
    padding: 0.25em 2em;
    border-radius: 3px;
    border: 1px solid #033F63;
    transition: all 0.5s ease-out;
}

.logButtonContainer button:hover {
    background-image: linear-gradient(180deg, #fdd96c 0%, #fbb426 100%);
}

@media screen and (max-width: 48em) {
    .accountContainer h1 {
        width: 100%;
    }
    
    .loginForm {
        width: 100%;
    }

    .logButtonContainer button,
    .logButtonContainer a {
        font-size: 1.2rem;
        padding: 0.25em 1em;
    }
}