.stateModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #444444;
    width: 50%;
    background-color: #fefefe;
    padding: 2em;
    border-radius: 5px;
    z-index: 101;
}

.stateModal > div {
    margin-left: 0.8em;
    margin-right: 0.8em;
    display: flex;
    justify-content: space-between;
}

.stateModal > div > p {
    color: #033F63;
    font-family: 'Architects Daughter', cursive;
    margin: 0;
    font-size: 1.5rem;
}

.stateModal > div > button {
    background-color: transparent;
    border: 0;
    font-family: inherit;
    font-size: 1.4rem;
}

.stateModal form {
    text-align: left;
}

.buttonContainer {
    margin-left: 0.8em;
    margin-right: 0.8em;
    display: flex;
    justify-content: space-between;
}

.buttonContainer button {
    padding: 0.5em;
    font-family: inherit;
    font-size: 1rem;
    transition: all 0.5s ease-out;
}

.buttonContainer button:first-child {
    background-color: transparent;
    border: 1px solid #444444;
}

.buttonContainer button:hover {
    background-color: #033F63;
    color: #fefefe;
}