.container {
    min-height: 650px;
}

.selectedHelpTopics {
    display: flex;
    flex-wrap: wrap;
}

.selectedHelpTopics>div>button {
    display: inline-block;
    background: none;
    border: 0;
    font-size: 1.25rem;
    color: #023250;
    padding: 0 0.5em;
    margin-right: 1.5em;
}

.selectedHelpTopics>div>h2 {
    display: inline-block;
	margin-top: 0.2em;
}

.transcriptContainer {
    flex: 0 0 75%;
    display: flex;
    height: 600px;
}

.transcriptContainer li {
    display: flex;
}

.transcriptContainer ul {
    flex: 0 0 20%;
	padding-left: 0;
}

.transcriptContainer li>img {
    display: inline;
    transition: all 500ms ease-out;
}

.transcriptContainer li>button {
    width: 300px;
    padding: 0.75em 2em 0.75em 0.75em;
    text-align: start;
    font-size: 1.4375rem;
    font-family: 'Architects Daughter', cursive;
    color: #033F63;
    white-space: nowrap;
    margin: 0.65em -10px 0.65em 0;
    border-radius: 10px;
    border-color: #033F63;
    background-color: #FEFEFE;
    position: relative;
    left: 5%;
    display: inline;
}

.transcriptContainer li>button:focus-visible {
    outline: 2px solid #033F63;
}

.transcriptContainer>div {
    flex: 1;
    margin-right: 2em;
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    border: 1px solid #033F63;
    height: 75%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-color: #FEFEFE;
}

.textContainer {
    overflow: auto;
    height: 75%;
    width: 94%;
	scrollbar-color: #033F63 #FEFEFE;
	scrollbar-width: auto;
}

.textContainer::-webkit-scrollbar {
    width: 1em;
    background-color: #033F63;
    border-radius: 4px;
}

.textContainer::-webkit-scrollbar-track {
    background-color: #FEFEFE;
    border: 2px solid #033F63;
    border-radius: 4px;
    position: relative;
}

.textContainer::-webkit-scrollbar-thumb {
    background-color: #033F63;
    width: 0.75em;
    border-radius: 4px;
}

.textContainer::-webkit-scrollbar-thumb:hover {
    background-color: #023250;
    width: 0.75em;
    border-radius: 4px;
}

.transcriptContainer>div::before {
    content: '';
    border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
    position: absolute;
    transform: rotate(-3deg);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.transcriptContainer>div>div>p {
    flex: 0 0 100%;
    padding: 1em 3em;
    transition: 1s ease-in-out;
}

.helpVideoControlsContainer {
    padding-left: 5em;
}

.helpVideoControlsMobileContainer {
    margin-top: 2em;
    text-align: center;
    width: 100%;
}

.helpVideoControlsContainer button,
.selectedHelpTopics .helpVideoControlsMobileContainer > button{
    color: #444444CC;
    padding: 0 0.3em;
    font-size: 2rem;
    background: none;
    border: none;
    transition: color 0.3s ease;
}

.helpVideoControlsContainer button:last-child i {
    transform: rotate(45deg);
}

.helpVideoControlsContainer button:hover {
    color: #033F63;
}

.helpVideoControlsContainer button:focus {
    color: #033F63;
}

.helpVideoContainer {
    flex: 0 0 20%;
    overflow: hidden;
    position: relative;
    z-index: -1;
}
.helpVideoContainer video { 
    width: 100%;
    position: relative;
}

.helpVideoContainer video::-webkit-media-controls {
    display: none !important;
}

.toggleInvisible {
    display: block;
}

.selectedHelpTopics>div>button.mobileButton {
    display: none;
}

p.mobileButton {
    display: none;
}

.modal {
    display: none;
}

.selectedHelpTopics article {
    display: none;
}

.helpVideoControlsMobileContainer {
    display: none;
}

@media screen and (max-width: 48em) {

    .helpVideoContainer {
        flex: 0 0 75%;
        margin: 0 auto;
        max-width: 40%;
    }

    .transcriptContainer {
        display: block;
        height: fit-content;
        flex-basis: 100%;
        margin: 0 auto;
        position: relative;
    }

    .transcriptContainer > div {
        display: none;
    }

    .transcriptContainer > ul {
        margin: 0 auto;
        width: 85%;
        border-radius: 4px;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 1em;
        color: #033F63;
        box-shadow: 2px 2px 8px #444444;
        position: absolute;
        background-color: #FEFEFE;
        z-index: 23;
        padding: 0.5em;
        top: -0.5em;
        left: 1.5em;
    }

    .transcriptContainer > ul > li img {
        display: none;
    }

    .transcriptContainer > ul > li {
        display: block;
        padding-bottom: 0.5em;
    }

    .transcriptContainer li>button {

        width: 100%;
        text-align: left;
        font-size: inherit;
        font-family: inherit;
        background-color: #FEFEFE;
        border: none;
        padding: 0;
        margin: 0;
        position: static;
    }

    .transcriptContainer li>button:focus-within {
        outline: none; 
        font-weight: bold;
    }

    .transcriptContainer li:focus-within     {
        font-weight: bold;
    }

    .toggleVisible {
        display: block;
    }

    .toggleInvisible {
        display: none;
    }

    .selectedHelpTopics>div>button.mobileButton {
        display: flex;
        width: 90%;
        padding-left: 2.3em;
        justify-content: space-between;
        margin-bottom: 2em;
        position: relative;
        font-family: inherit;
        font-weight: bold;
    }

    li.mobileButton:first-of-type {
        display: block;
    }

    p.mobileButton:first-of-type {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        font-size: 1.2rem;
    }

    .selectedHelpTopics>div>button {
        display: inline-block;
        background: none;
        border: 0;
        font-size: 1.25rem;
        color: #023250;
        padding: 0 0.5em;
        margin-right: 0.5em;
    }

    .modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 22;
        display: block;
    }

    .selectedHelpTopics article {
        display: block;
    }

        .helpVideoControlsMobileContainer {
            display: block;
        }
}

@media screen and (max-width: 20em) {
    .container h1 {
        font-size: 2rem;
    }
}