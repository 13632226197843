.testimonial {
	margin: 4em auto 4em auto;
	max-width: 800px;
	padding: 2em;
	border-top-left-radius: 255px 25px;
	border-top-right-radius: 35px 255px;
	border-bottom-right-radius: 225px 35px;
	border-bottom-left-radius: 45px 255px;
	border: 2px solid #444444;
	background-color: #F9D975;
}

.quote {
	font-size: 1.5rem;
	margin-bottom: 0.5em;
}

.byline {
	text-align: right;
	font-style: italic;
}